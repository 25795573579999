import React from "react";

export default function Navbar() {
  let currentYear = new Date().getFullYear();

  return (
    <footer>
      <p>© {currentYear} GODS OF FORTUNE LLC</p>
    </footer>
  );
}
