import React from "react";

import "./App.css";

import Navbar from "./components/Navbar.js";
import Glyph from "./components/Glyph";
import Footer from "./components/Footer.js";

function App() {
  return (
    <div className="App">
      <Navbar />
      <main className="main">
        <header className="main-text">
          <Glyph character="幸" label="Kō" lang="ja" />
          <Glyph character="運" label="Un" lang="ja" />
          <Glyph character="の" label="No" lang="ja" />
          <Glyph character="神" label="Kami" lang="ja" />
          <Glyph character="々" label="kanji repetition symbol" lang="ja" />
        </header>
      </main>
      <Footer />
    </div>
  );
}

export default App;
