import React from "react";

export default function Navbar() {
  return (
    <nav>
      <a id="summon" href="mailto:apps@godsoffortune.io">
        SUMMON
      </a>
    </nav>
  );
}
