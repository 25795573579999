import React from "react";

export default function Glyph(props) {
  const { character, label, lang } = props;

  return (
    <span
      tabIndex={0}
      role="region"
      aria-label={label}
      lang={lang}
      className="main-text"
    >
      {character}
    </span>
  );
}
